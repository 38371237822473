import React from 'react';
import axios from 'axios';
import config from './config';
import { IAccount, IInfo, ILogin, ILoginResponse, IRegister, IRegisterResponse } from '../models/auth-model';
import GetAccessToken from './access-token-managed';

const wrapBackend = (path: string) => {
  return config.backend + path;
};
const Login = async (email: string, password: string) => {
  return axios.post<ILoginResponse>(wrapBackend('/api/Auth/login'), {
    email,
    password
  });
};
const Register = async (name: string, email: string, password: string) => {
  return axios.post<IRegisterResponse>(wrapBackend('/register'), {
    name,
    email,
    password
  });
};

const RequestNewPasswordServices = async (email: string) => {
  return axios.post<ILoginResponse>(wrapBackend('/api/Auth/request_recovery_password'), {
    email
  });

};

const NewPasswordServices = async (email: string, token: string, newpassword: string, ConfirmPassword: string) => {
  return axios.post<ILoginResponse>(wrapBackend('/api/Auth/recovery_password'), {
    'email': email,
    'token': token,
    'NewPassword':  newpassword,
    'ConfirmPassword': ConfirmPassword
  });

};
const Account = async () => {
  return axios.get<IAccount>(wrapBackend('/api/Auth/account'), {
    ...GetAccessToken()
  });
};
const Info = async () => {
  return axios.get<IInfo>(wrapBackend('/register/info'));
};

export { Login, Register, Info, Account, RequestNewPasswordServices, NewPasswordServices };