import {createContext, FC, useContext, useEffect, useState} from "react";
import {IAccount} from "../models/auth-model";
import {useNavigate} from "react-router-dom";

type IAuthContext = {
    user?: IAccount,
    signIn: (newUser: IAccount) => void;
    signOut: () => void;
}
export const AuthContext = createContext<IAuthContext>(
    {} as IAuthContext
);
export const useAuthContext = () => {
    return useContext(AuthContext);
};
export const AuthProvider: FC = ({children}) => {
    const [account, setAccount] = useState<IAccount>();
    const navigate = useNavigate()
    const signIn = (newUser: IAccount) => {
        console.log("Facultad" + newUser.facultad)
        setAccount(newUser)
        //navigate("/management/bots")
    }
    const signOut = () => {
        localStorage.removeItem("accessToken");
        setAccount(null);
        navigate("/")
    }
    return (
        <AuthContext.Provider
            value={{user: account, signIn: signIn, signOut}}
        >
            {children}
        </AuthContext.Provider>
    );
};