import { createContext, FC, useContext } from 'react';

import { useSnackbar } from 'notistack';

type IAlertContext = {
  showAlert: (message: string, state: 'error' | 'success' | string, autoHide?: number) => void;
}
export const AlertsContext = createContext<IAlertContext>(
  {} as IAlertContext
);
export const useAlertsContext = () => {
  return useContext(AlertsContext);
};
export const AlertsProvider: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (message: string, state: 'error' | 'success', autoHide = 4000) => {
    console.log('showAlert executed');
    enqueueSnackbar(message, {
      variant: state,
      autoHideDuration: autoHide,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      disableWindowBlurListener: true
    });
  };
  return (
    <AlertsContext.Provider value={{ showAlert: showAlert }}>
      {children}
    </AlertsContext.Provider>
  );
};