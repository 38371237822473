import config from './config';
import { IIndicador, IndicadorReport } from '../models/indicador';
import axios from 'axios';
import GetAccessToken from './access-token-managed';


const wrapBackend = (path: string) => {
  return config.backend + path;
};

const GetIndicatorsCarreras = async (type: number) => {
  return axios.get<IndicadorReport[]>(wrapBackend('/api/admin/indicator/dashboard/'+type), {...GetAccessToken()});
};

const GetLastIndicatorsFaculty = async (type: number) => {
  return axios.get<IndicadorReport[]>(wrapBackend('/api/faculty_platform/dashboard/'+type), {...GetAccessToken()});
};

const GetLastIndicatorsSu = async (type: number) => {
  return axios.get<IndicadorReport[]>(wrapBackend('/api/superuser/indicator/dashboard/'+type), {...GetAccessToken()});
};
export { GetIndicatorsCarreras, GetLastIndicatorsFaculty, GetLastIndicatorsSu };