import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress, Grid, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from './logo-sm.png'
const zoomAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.14);
    }
    100% {
        transform: scale(0.99);
    }
`;

// Estilos para la imagen con la animación
const ZoomImage = styled('img')({
  transition: 'transform 0.5s ease-in-out',
  animation: `${zoomAnimation} 1s infinite`
});

function SuspenseLoader() {


  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        alignItems="center"
        justifyItems="center"
        flexDirection="column"
        style={{
          minWidth: '100%',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <ZoomImage src={Image}></ZoomImage>
      </Grid>
    </Box>
  );
}

export default SuspenseLoader;
