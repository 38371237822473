import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { Box, CircularProgress, CssBaseline, Grid, keyframes, makeStyles } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Account } from './services/auth-services';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';
import { IAccount } from './models/auth-model';
import { AlertsProvider } from './contexts/AlertsContext';
import { Image } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
// Definición de la animación con MUI styled
const zoomAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.14);
    }
    100% {
        transform: scale(0.99);
    }
`;

// Estilos para la imagen con la animación
const ZoomImage = styled('img')({
  transition: 'transform 0.5s ease-in-out',
  animation: `${zoomAnimation} 1s infinite`
});

function App() {
  const content = useRoutes(router);
  const authContext = useAuthContext();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    Account()
      .then((rs) => {
        let data: IAccount = rs.data;
        if (rs.status != 200) {
          if (location.pathname == '/recovery-password-request' || location.pathname == '/recovery_password') {
            return;
          }
          authContext.signOut();
          return;
        }
        authContext.signIn(data);
      })
      .catch((err) => {
        if (location.pathname == '/recovery-password-request' || location.pathname == '/recovery_password') {
          return;
        }
        authContext.signOut();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider>
        <AlertsProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            {loading ? (
              <Grid
                alignItems="center"
                justifyItems="center"
                flexDirection="column"
                style={{
                  minWidth: '100%',
                  minHeight: '100vh',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {/*<ZoomImage src="./logo-sm.png"></ZoomImage>*/}
              </Grid>
            ) : (
              content
            )}
          </LocalizationProvider>
        </AlertsProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
