import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import DashboardFaculty from './content/dashboards/FacultyDash';
import DashboardSu from './content/dashboards/SuDash';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards
const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));
const DashBoardTask = Loader(lazy(() => import('src/content/dashboards/Tasks')));
const DashBoardSu = Loader(lazy(() => import('src/content/dashboards/SuDash')));
// Applications
const RecoveryPassword = Loader(
  lazy(() => import('src/content/overview/Hero/send_request_recovery_password'))
);

const RenewPassword = Loader(
  lazy(() => import('src/content/overview/Hero/renew_password'))
);
const Equipos = Loader(
  lazy(() => import('src/content/applications/Equipos'))
);
const EquipoDetail = Loader(
  lazy(() => import('src/content/applications/EquipoDetail'))
);
const UserList = Loader(
  lazy(() => import('src/content/applications/Users'))
);
const FacultadList = Loader(
  lazy(() => import('src/content/applications/Facultades'))
);
const CarrerasList = Loader(
  lazy(() => import('src/content/applications/Carreras'))
);
const IndicadoresList = Loader(
  lazy(() => import('src/content/applications/Indicadores'))
);
const ReportesList = Loader(
  lazy(() => import('src/content/applications/Reportes'))
);

const ResumenFacultyInd = Loader(
  lazy(() => import('src/content/applications/ResumenFacultadAdmin'))
);

const IndicadoresAsociados = Loader(
  lazy(() => import('src/content/applications/IndicadoresAsociados'))
);
const ReportesListFaculty = Loader(
  lazy(() => import('src/content/applications/ReportesFaculty'))
);
const IndicadoresPendingList = Loader(
  lazy(() => import('src/content/applications/IndicadoresPendientes'))
);
// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: 'recovery_password',
        element: <RenewPassword />
      },
      {
        path: 'recovery-password-request',
        element: <RecoveryPassword />
      },
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />
      },
      {
        path: 'tasks',
        element: <Tasks />
      },
      {
        path: 'faculty',
        element: <DashboardFaculty />
      },
      {
        path: 'su',
        element: <DashBoardSu />
      }
    ]
  },
  {
    path: 'administracion',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Tasks />
      },
      {
        path: 'reportes',
        element: <ReportesList />
      },
      {
        path: 'resumen',
        element: <ResumenFacultyInd />
      },
      {
        path: 'indicadores_asociados',
        element: <IndicadoresAsociados />
      }
    ]
  },
  {
    path: 'su',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <DashBoardSu />
      },
      {
        path: 'usuarios',
        element: <UserList />
      },
      {
        path: 'facultades',
        element: <FacultadList />
      },
      {
        path: 'carreras',
        element: <CarrerasList />
      },
      {
        path: 'indicadores',
        element: <IndicadoresList />
      }
    ]
  },
  {
    path: 'facultad',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <DashboardFaculty />
      },
      {
        path: 'carreras',
        element: <CarrerasList />
      },
      {
        path: 'indicadores-pendientes',
        element: <IndicadoresPendingList />
      },
      {
        path: 'reportes',
        element: <ReportesListFaculty />
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
