import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Box,
  Menu,
  alpha,
  MenuItem,
  Typography,
  styled,
  useTheme, Autocomplete
} from '@mui/material';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import {  GetLastIndicatorsFaculty } from '../../../services/dashboard-services';
import { IIndicador, IndicadorReport } from '../../../models/indicador';

const TasksAnalytics = ({ title }) => {
  const theme = useTheme();
  const [indicatorsFaculty, setIndicatorsFaculty] = useState<IndicadorReport[]>(null);
  useEffect(() => {
    loaderIndicators();
  }, []);

  const loaderIndicators = () => {
    GetLastIndicatorsFaculty(0).then((rs) => {
      if (rs.status != 200) {
        return;
      }
      setIndicatorsFaculty(rs.data);
    });
  };

  const categories = indicatorsFaculty?.map((item) => item.indicador_nombre);
  const seriesData = indicatorsFaculty?.map(indicator => ({
    x: indicator.indicador_nombre,
    y: indicator.valor, // Replace with actual data if available
    goals: [
      {
        name: 'Mínimo',
        value: indicator.puntaje_minimo,
        strokeWidth: 5,
        strokeDashArray: 0,
        strokeColor: '#ed4238'
      },
      {
        name: 'Máximo',
        value: indicator.puntaje_maximo,
        strokeWidth: 5,
        strokeDashArray: 0,
        strokeColor: '#43dc18'
      }
    ]
  }));

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      type: 'bar',
      zoom: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        distributed: true
      }
    },
    colors: [theme.colors.primary.main, alpha(theme.colors.primary.main, 0.5)],
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    theme: {
      mode: theme.palette.mode
    },
    stroke: {
      show: true,
      width: 3,
      colors: ['transparent']
    },
    legend: {
      show: false
    },
    grid: {
      strokeDashArray: 5,
      borderColor: theme.palette.divider
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      tickAmount: 6,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return 'Valor: ' + val + '';
        },
        title: {
          formatter: function(seriesName) {
            return '';
          }
        }
      }
    }
  };

  const chartData = [
    {
      data: seriesData
    }
  ];


  return (
    <Box>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">{title}</Typography>
        <Box display="flex"
             flexDirection="row"
             alignItems="center">
        </Box>
      </Box>

      <Chart
        options={chartOptions}
        series={chartData}
        type="bar"
        height={200}
      />
    </Box>
  );
};


const AnalyticsCareer = ({ title }) => {
  const theme = useTheme();
  const [indicatorsCarrer, setIndicatorsCarrer] = useState<IndicadorReport[]>(null);
  useEffect(() => {
    loaderIndicators();
  }, []);

  const loaderIndicators = () => {
    GetLastIndicatorsFaculty(1).then((rs) => {
      if (rs.status != 200) {
        return;
      }
      console.log(rs.data)
      setIndicatorsCarrer(rs.data);
    });
  };

  const categories = indicatorsCarrer?.map((item) => item.indicador_nombre);
  const seriesData = indicatorsCarrer?.map(indicator => ({
    x: indicator.puntaje_minimo,
    y: indicator.valor, // Replace with actual data if available
    goals: [
      {
        name: 'Mínimo',
        value: indicator.puntaje_minimo,
        strokeWidth: 5,
        strokeDashArray: 0,
        strokeColor: '#ed4238'
      },
      {
        name: 'Máximo',
        value: indicator.puntaje_maximo,
        strokeWidth: 5,
        strokeDashArray: 0,
        strokeColor: '#43dc18'
      }
    ]
  }));
  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      type: 'bar',
      zoom: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        distributed: true
      }
    },
    colors: [theme.colors.primary.main, alpha(theme.colors.primary.main, 0.5)],
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    theme: {
      mode: theme.palette.mode
    },
    stroke: {
      show: true,
      width: 3,
      colors: ['transparent']
    },
    legend: {
      show: false
    },
    grid: {
      strokeDashArray: 5,
      borderColor: theme.palette.divider
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      tickAmount: 6,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return 'Valor: ' + val + '';
        },
        title: {
          formatter: function(seriesName) {
            return '';
          }
        }
      }
    }
  };

  const chartData = [
    {
      data: seriesData
    }
  ];

  return (
    <Box>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">{title}</Typography>
        <Box display="flex"
             flexDirection="row"
             alignItems="center">
        </Box>
      </Box>

      <Chart
        options={chartOptions}
        series={chartData}
        type="bar"
        height={200}
      />
    </Box>
  );
};
export {
  TasksAnalytics,
  AnalyticsCareer
} ;