const GetAccessToken = (headers = {}) => {
    console.log("GetAccessToken");
    let token = localStorage.getItem("accessToken");
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            ...headers
        }
    }
}

export default GetAccessToken;